import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第一輪</h2>
          <h1 className="main_title">#公民社會</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_7">
              <figure className="figure">
                <img src={require("../../images/blog/post_2_2_2.jpg")} alt=""/>
                <figcaption className="figcaption left">組長：林書豪<br/>組員：姚仲涵、張柏韋、陳姵潔</figcaption>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>社宅公共藝術在公民社會中的角色。公共性、開放性非常重要，核心思想是創造地區共同文化價值，扮演誘發對話的角色，創造社宅與周邊對話的可能。</p>


                <h4>1.<strong>藝術應做為持續的行動</strong> ：可以是有形或無形</h4>


                <h4>2.永續性經營需要<strong>「健全的資本生態鏈」</strong>：沒有商業機制，如何實踐永續經營？</h4>


                <h4>3.公共空間的策略</h4>
                <p>有幾個地方可以被處理，包含公共空間、遊戲區、中介空間和色彩等。思考這些空間設計如何對應建築設計，或是公共藝術如何運用空間來經營社區、公民社會。</p>


                <h4>4.公共性和私密性</h4>
                <p>藝術家進社區做參與，可能有人不想有任何接觸，我們也需尊重每個人參與活動的意向。我們相信人，利用人的本性或是個人性格，也相信藝術的力量。</p>


                <h4>5.公民社會和藝術的關係</h4>
                <p>回到一個根本的提問：藝術可以作什麼？</p>
                <p>社會住宅被貼上弱勢標籤，是臺灣的特別處境。即便透過論述講清楚這件事，裂縫依然存在。組員認為社宅公共藝術不僅在談論如何處理問題，更是透過藝術的力量感動彼此，讓被撕裂的社區與族群攜手往前共同生活。</p>


                <h4>6.公共不該是私人佔領的剩餘</h4>
                <p>我們希望公共是被大家共同建立出來的，不斷持續前進的空間。</p>


                <h6>小結：</h6>
                <p>很多機制或政策無法營利，經費只好不斷補助，沒有辦法培養人，也無法達到永續。</p>
                <p>社宅空間的規劃處理要回應公民社會的需求，大家在公共空間會互相碰到、交流，空間創作如何對應到建築設計，公共藝術如何運用這個空間來經營社區、公民社會，需要策略和思考。</p>
                <p>如北川富朗所言，我們要「相信人、相信藝術的力量」。撕裂的社區與族群，要靠藝術的力量來感動彼此，攜手共同生活。</p>
                <p>期待公共藝術不只是單一的藝術創作行動，而是有不斷持續前進的空間，讓社會能一直感受到藝術──把我們彼此拉在一起的力量。 </p>

              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_3" className="next_page">
            <div className="next_title">#機制執行</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第一輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
